<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.PROPERTIES") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>

              <CustomFieldComponent
                v-if="formModel && formModel.custom_fields"
                :config="{
                  type: 'multi_checkbox',
                  label: 'Multi select',
                  items: [
                    { label: 'Több választási lehetőség', value: 'true' },
                  ],
                }"
                customFieldName="multiCHK"
                :value="formModel.custom_fields.multiCHK || []"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <CustomFieldComponent
                v-if="formModel && formModel.custom_fields"
                :config="{
                  type: 'multi_checkbox',
                  label: 'Szám érték',
                  items: [{ label: 'Szám érték', value: 'true' }],
                }"
                customFieldName="isNumber"
                :value="formModel.custom_fields.isNumber || []"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>

              <!-- <CustomFieldComponent
                  v-if="formModel && formModel.custom_fields"
                  :config="{
                    type: 'multi_checkbox',
                    label: 'FORM_INPUT_NAMES.select_public_languages',
                    items: publicLocales,
                  }"
                  customFieldName="publicLanguages"
                  :value="formModel.custom_fields.publicLanguages || []"
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </CustomFieldComponent> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                :rules="nameRules"
                v-model="formModel.translations[selectedLocale.lang].name"
                :label="$t('FORMS.name')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
              <!-- <v-text-field
                v-if="
                  formModel.id && formModel.translations[selectedLocale.lang]
                "
                v-model="formModel.translations[selectedLocale.lang].slug"
                :label="$t('FORMS.slug')"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.slug']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.slug'] = ''
                "
              ></v-text-field> -->
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'text',
                  label: $t('FORM_INPUT_NAMES.quantity_unit'),
                }"
                customFieldName="quantityUnit"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .quantityUnit
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <!-- <v-col cols="12" sm="4" md="4" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="gallery"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .gallery || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 10,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.description',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.select_image',
                  initialColumnClass:
                    'col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-4',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col> -->
          </v-row>
          <v-form
            ref="formProperty"
            v-model="formPropertyValid"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
                v-if="
                  inputData.translations &&
                  inputData.translations[selectedLocale.lang]
                "
              >
                <v-text-field
                  v-for="(language, index) in languages"
                  v-model="inputData.translations[language.lang]['name']"
                  :label="$t('FORMS.name') + ' (' + language.lang + ')'"
                  :key="`language-${index}`"
                  :rules="[
                    (value) => {
                      return genericRequiredRule(
                        value,
                        'Csak szám lehet a mező értéke'
                      );
                    },
                  ]"
                ></v-text-field>

                <v-btn color="primary" @click="handleAddInputData">
                  {{ $t("FORMS.add") }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <DeleteModalDialog
                  :dialogDelete="dialogDelete"
                  @closeDelete="handleCloseDelete"
                  @deleteItemConfirm="handleDeleteItemConfirm"
                >
                </DeleteModalDialog>
                <v-data-table
                  :headers="headers"
                  :items="values"
                  :search="search"
                  @click:row="handleClickItem"
                >
                  <template v-slot:item.translated="{ item }">
                    <v-icon v-if="item.translated" color="green" small
                      >check_circle</v-icon
                    >
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      color="primary"
                      v-if="permissionCan('delete')"
                      small
                      @click="deleteItem(item)"
                      :disabled="selectedItemIndex"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
          :disabled="formModel.values.length == 0"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Properties";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";

const TRANSLATED_ATRIBUTES = ["name", "slug", "text", "position"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = ["quantityUnit"];

export const INITIAL_CUSTOM_FIELDS = {
  multiCHK: [],
  isNumber: [],
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  translations: {},
  custom_fields: INITIAL_CUSTOM_FIELDS,
  // properties: [],
  values: [],
});

export default {
  name: "PageForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  // components: { CustomFieldComponent },
  components: { DeleteModalDialog, CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formPropertyValid: false,
      validProperty: false,
      dialogDelete: false,
      permissionTo: PERMISSION_TO,

      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },

      headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
        },

        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],
      inputData: {},
      selectedItemIndex: null,
    };
  },
  computed: {
    // ...mapGetters([CURRENCY_COLLECTION]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.PROPERTIES");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },

    values() {
      return this.formModel.values.map((item) => {
        let translated = item.translations[this.selectedLocale.lang];
        item.translated = false;
        if (translated && translated.name) {
          item.name = translated.name;
          item.translated = true;
        } else {
          translated = item.translations[Object.keys(item.translations)[0]];
          item.name = translated.name;
        }
        return item;
      });
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        this.resetInputData();
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    genericRequiredRule(value, msg) {
      if (
        this.formModel.custom_fields &&
        this.formModel.custom_fields.isNumber &&
        this.formModel.custom_fields.isNumber.length > 0 &&
        isNaN(value)
      ) {
        return msg;
      }

      return true;
    },

    resetInputData() {
      let inputData = null;
      inputData = {};
      inputData.translations = {};
      this.languages.forEach((item) => {
        inputData.translations[item.lang] = {};
        inputData.translations[item.lang]["name"] = "";
      });

      this.inputData = Object.assign({}, inputData);
      if (this.$refs.formProperty) this.$refs.formProperty.resetValidation();
      this.formPropertyValid = false;
    },

    validate() {
      this.$refs.formProperty.validate();
    },

    handleAddInputData() {
      let valid = this.$refs.formProperty.validate();

      if (valid) {
        this.formModel.values.push(Object.assign(this.inputData));
        this.resetInputData();
        this.selectedItemIndex = null;
      }
    },
    deleteItem(item) {
      if (this.selectedItemIndex === null) {
        this.dialogDelete = true;
        this.selectedItemIndex = this.formModel.values.indexOf(item);
      }
    },

    handleDeleteItemConfirm() {
      this.formModel.values.splice(this.selectedItemIndex, 1);
      this.handleCloseDelete();
    },

    handleCloseDelete() {
      this.selectedItemIndex = null;
      this.dialogDelete = false;
    },

    handleClickItem(item) {
      if (this.selectedItemIndex === null) {
        this.selectedItemIndex = this.formModel.values.indexOf(item);
        // console.log("selectedItemIndex", this.selectedItemIndex);
        this.inputData = Object.assign({}, item);
        this.formModel.values.splice(this.selectedItemIndex, 1);
      }
    },

    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;

        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
  },

  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
